import cx from 'classnames';
import dynamic from 'next/dynamic';

import styles from './index.module.scss';

const Typography = dynamic(() => import('#app/UI/atoms/typography-updated'));

const TrustInUs = ({ list, background = 'white' }) => {
  return (
    <section
      className={cx(styles.wrapper, 'sm_mr-20 sm_ml-20')}
      style={{ background: background && background }}
    >
      <div className={styles.container}>
        <Typography className="mb-24 text-center" tag="h2">
          <span style={{ color: '#468EFF' }}>Más de 50 organizaciones</span> confían en
          nosotros
        </Typography>

        <Typography
          className={cx(styles.description, 'text-center')}
          tag="body-big"
          style={{ color: '#001F19' }}
        >
          ¡Difundamos la tecnología en toda la región para transformar la juventud!
        </Typography>

        <div className={cx(styles.containerlist)}>
          <ul className={cx(styles.firstlist)}>
            {list.length > 0
              ? list.map(({ image }, idx) => (
                  <li key={`image-logo-${idx}`}>
                    <img src={image} alt="logo" />
                  </li>
                ))
              : null}
          </ul>
          {/* <ul className={cx(styles.secondlist)}>
            {list.length > 5
              ? list.slice(5, list.length + 1).map(({ image }, idx) => (
                  <li key={`image-logo-${idx}`}>
                    <img src={image} alt="logo" />
                  </li>
                ))
              : null}
          </ul> */}
        </div>
      </div>
    </section>
  );
};

export default TrustInUs;
